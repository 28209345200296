<template>
  <form class="form">
    <FveSelectObject
      label="Имя тренера"
      name="trainer-1dfsz"
      required
      v-model="form.trainer"

      :optionList="trainerList"
      :optionGetKey="obj => obj.id"
      :optionGetName="obj => obj.name"
    />
    <FveSelectObject
      label="Площадка"
      name="place-1dfsz"
      required
      v-model="form.place"
      :optionList="placeList"
      :optionGetKey="obj => obj.id"
      :optionGetName="obj => obj.name"
    />
    <FveSelectObject
      label="Тариф"
      name="tariff-1dfsz"
      required
      v-model="form.tariff"
      :optionList="tariffList"
      :optionGetKey="(obj) => { return obj.id.toString();}"
      :optionGetName="(obj) => { return obj.name + ' - ' + obj.representation;}"
    />
    <div v-if="form.tariff" class="row">
      <div class="col-6">
        <div class="fve">
          <div class="fve-label">Формат тренировки</div>
          <div class="fve-field">
            <div class="fve-control">
              {{ ageList[form.tariff.age] }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="fve">
          <div class="fve-label">Максимальное число посетителей</div>
          <div class="fve-field">
            <div class="fve-control">
              {{ form.tariff.capacity - 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row--fix">
      <div class="col-6">
        <FveSelectObject
          label="Повторять"
          name="repeat-1dfsz"
          required
          v-model="form.repeat"
          :optionList="[
            { id: 'ONCE', name: 'Однократно'    },
            { id: 'DALY', name: 'Каждый день'   },
            { id: 'WKLY', name: 'Каждую неделю' },
            { id: 'MNLY', name: 'Каждый месяц'  },
          ]"
          :optionGetKey="obj => obj.id"
          :optionGetName="obj => obj.name"
        />
      </div>
      <div class="col-6">
        <FveSelectObject

          v-if=" form.repeat && form.repeat.id !== 'ONCE' "

          label="Повторять в течение"
          name="span-1dfsz"
          required
          v-model="form.span"
          :optionList="[
            { id: 'MNTH', name: 'Месяца'    },
            { id: 'HLFY', name: 'Полугода'  },
            { id: 'YEAR', name: 'Года'      },

          ]"
          :optionGetKey="obj => obj.id"
          :optionGetName="obj => obj.name"
        />
      </div>
      <div class="col-6">
        <FveDatePicker
          label="День"
          name="date"
          required
          v-model="form.date"
        />
      </div>
      <div class="col-6">
        <FveTime
          label="Время начала"
          name="start_time"
          required
          v-model="form.start_time"
        />
      </div>
    </div>
  </form>
</template>

<script>

import FveTime from "@widgetFormValidate/src/Element/Text/FveTime";
import FveDatePicker from "@widgetFormValidate/src/Element/Date/FveDatePicker";
// import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";
// import FveSelectAjaxV2Place from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Place";
import FveSelectObject from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";
// import FveSelectAjaxV2Tariff from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Tariff";


//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveTime,
    FveDatePicker,
    // FveSelectAjaxV2Trainer,
    // FveSelectAjaxV2Place,
    FveSelectObject,
    // FveSelectAjaxV2Tariff,
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      trainerList: [],
      placeList: [],
      trainerTypeList: [],
      ageList: {},
    };
  },
  methods: {
    formSchema() {
      return {
        trainer     : { type: Object, default: () => { return null; } },
        tariff      : { type: Object, default: () => { return null; } },
        place       : { type: Object, default: () => { return null; } },
        date        : { type: String, default: () => { return (new DateTime()).format('YYYY-MM-DD'); } },
        start_time  : { type: String, default: () => { return ''; } },
        repeat      : { type: Object, default: () => { return { id: 'ONCE', name: 'Однократно'    };  } },
        span        : { type: Object, default: () => { return null; } },
      };
    },
  },
  computed: {
    tariffList() {
      return this.form.trainer ? this.form.trainer.tariff_set : [];
    }
  },
  created() {
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      this.trainerTypeList = list;
    });

    RequestManager.Club.getClubRoomList({
      clubId: this.clubId,
    }).then( (response) => {
      this.placeList = response.results;
    });

    RequestManager.Club.getClubTrainerList({
      clubId: this.clubId,
    }).then( (itemList) => {
      this.pageCount = itemList.count;
      this.trainerList = itemList.results.map((item) => {
        return {
          id        : item.id,
          img       : item.avatar,
          name      : `${item.last_name} ${item.first_name} ${item.middle_name}`,
          tariff_set: item.tariff_set,
        };
      });
    });
  
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      const _ageList = {};
      list.forEach(i => _ageList[i.key] = i.value);
      this.ageList = _ageList;
    });
  }
};

</script>

<style lang="scss" scoped>
// fve
.fve {
  margin-bottom: 24px;
  .fve-label {
    display: inline-block;
    margin: 0 0 4px 0;
    padding: 0 0 0 0;
    color: #ACACAC;
    font-size: 14px;
    line-height: inherit;
    transition: color 0.15s ease-in-out;
  }
  .fve-control {
    padding: 11px 16px;
    color: var(--text-sub);
    font-size: 17px;
    font-weight: 400;
    background-color: #2D2D2D;
    border-radius: 4px;
    opacity: 0.5;
    &--inline {
      display: inline-block;
    }
  }
}

</style>
