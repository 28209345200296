<template>
  <div class="slot" style="height: 100%">

    <div v-if="slotInfo.length > 1" class="slot__num">{{slotInfo.length}}</div>
    <template v-if="slotInfo.length >= 1">
      <div class="slot__wp" @click="showDetails">
        <template v-if="slotInfo.length > 1">
          <div class="slot__ell" :class="'slot__ell--' + slotStatus(slotInfo[0])"></div>
          <div class="slot__ell" :class="'slot__ell--' + slotStatus(slotInfo[1])" >
            <div class="slot__text">{{slotInfo[0].tariff.name}}</div>
            <div class="slot__text">{{slotInfo[0].court.name}}</div>
          </div>
        </template>
        <template v-else>
          <div class="slot__ell" :class="'slot__ell--' + slotStatus(slotInfo[0])" >
            <div class="slot__text">{{slotInfo[0].tariff.name}}</div>
            <div class="slot__text">{{slotInfo[0].court.name}}</div>
          </div>
        </template>

      </div>

      <div class="slot-dropdown" v-if="details">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.5 15.5" v-bind:svg-inline="''" v-bind:class="'slot-dropdown__close'" @click="hideDetails" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#555"><path d="M.76 15.49a.75.75 0 01-.53-1.28l14-14a.75.75 0 011.06 1.06l-14 14a.75.75 0 01-.53.22z"/><path d="M14.75 15.5a.75.75 0 01-.53-.22l-14-14A.75.75 0 011.28.22l14 14a.75.75 0 01-.53 1.28z"/></g></svg>
        <div class="slot-dropdown__head">{{ getDate(slotInfo[0].date) }} {{ slotInfo[0].start_time.substring(0, 5) }} - {{ slotInfo[0].end_time.substring(0, 5) }}</div>
        <div class="slot-dropdown__list">
          <template v-for="item in slotInfo">
            <div class="slot-dropdown__item" :key="item.index">
              <span>{{item.tariff.name}}</span><br/>
              <span>
                {{ item.court.name }} •
                {{ item.trainer.last_name }} •
                {{ ageList[item.tariff.age] }} •
                свободных мест: {{ item.capacity_left }}
              </span>
            </div>
          </template>
        </div>
      </div>

    </template>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    slotInfo: {
      type: Array,
      required: true
    },
    ageList: Object,
  },
  data() {
    return {
      details: false,
    };
  },
  methods: {
    showDetails() {
      this.details = true;
    },
    hideDetails() {
      this.details = false;
    },
    slotStatus(ell) {
      let status = '';
      let pc = ell.participants_capacity - 1;
      let cl = ell.capacity_lef;
      if(cl === 0) {
        status = 'red';
      } else if (cl === pc) {
        status = 'green';
      } else {
        status = 'orange';
      }
      return status;
    },
    getDate(str) {
      let date = new Date(str);
      return date.toLocaleString('ru', {day: 'numeric', month: 'numeric', year: 'numeric'});
    }
  },
};
</script>

<style lang="scss" scoped>


// slot
.slot {
  position: absolute;
  width: 100%;
  .slot__num {
    position: absolute;
    left: -8px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    border-radius: 50%;
    background-color: var(--color-prime);
    z-index: 2;
  }
  .slot__ell {
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    cursor: pointer;
    .slot__text {
      color: #FFFFFF;
      font-size: 12px;
    }
    &:nth-child(2) {
      margin-top: 8px;
      margin-left: 8px;
    }
    &--green {
      border: 1px solid #CAF12E;
      background-color: rgba(202, 241, 46, 0.12);
    }
    &--orange {
      border: 1px solid #FFC107;
      background-color: rgba(255, 193, 7, 0.15);
    }
    &--red {
      border: 1px solid #E54C4C;
      background-color: rgba(220, 53, 69, 0.15);
    }
  }
}
.slot-dropdown {
  position: absolute;
  right: 0;
  top: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #CAF12E;
  background-color: var(--bg-prime);
  z-index: 5;
  .slot-dropdown__close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 10px;
    height: auto;
    cursor: pointer;
    path {
      transition: all .2s ease-in-out;
    }
    &:hover {
      path {
        fill: var(--color-prime);
      }
    }
  }
  .slot-dropdown__head {
    margin-bottom: 8px;
    padding-right: 10px;
    color: var(--text-descr);
    font-size: 14px;
    white-space: nowrap;
  }
  .slot-dropdown__list {
    .slot-dropdown__item {
      margin-bottom: 8px;
      color: var(--text-sub);
      font-size: 14px;
      white-space: nowrap;
      cursor: pointer;
      transition: all .2s ease-in-out;
      span {
        display: inline-block;
        margin-bottom: 4px;
      }
      &:hover {
        color: var(--color-prime);
      }
    }
  }
}

</style>
