<template>
  <ClubTemplate
    :clubId="clubId"
    add="Добавить тренировку"
    @clickAdd="showModal"
  >
    <div class="row">
      <div class="col-4">
        <FveSelectObject
          label="Тренер"
          v-model="trainer"

          :optionList="trainerList"
          :optionGetKey="obj => obj.id"
          :optionGetName="obj => obj.name"
        />
      </div>
      <div class="col-4">
        <FveSelectObject
          label="Зал"
          v-model="place"

          :optionList="placeList"
          :optionGetKey="obj => obj.id"
          :optionGetName="obj => obj.name"
        />
      </div>
      <div class="col-3">
        <b-button size="lg" variant="primary" class="mt-fix" @click="updateFilter">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.5 8.483v-7.14" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M4.5 19.342a2.843 2.843 0 110-5.685 2.843 2.843 0 010 5.685z" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.5 12.2v7.143" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M15.5 1.343a2.842 2.842 0 10-.001 5.684 2.842 2.842 0 00.001-5.684z" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          Отфильтровать
        </b-button>
      </div>
    </div>
    <div class="schedule">
      <div class="schedule__control">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'schedule__control-icon schedule__control-icon--prev'" @click="prevCalendar" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.193 5.445a.687.687 0 00.047-.874.573.573 0 00-.883-.056l-4.8 5.02a.673.673 0 00-.182.465c0 .167.06.337.182.465l4.8 5.02c.257.268.666.231.883-.056a.687.687 0 00-.047-.874L7.838 10l4.355-4.555z" fill="#989898"/></svg>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'schedule__control-icon schedule__control-icon--next'" @click="nextCalendar" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.807 14.555a.687.687 0 00-.047.874.573.573 0 00.883.055l4.8-5.02a.673.673 0 00.182-.464.673.673 0 00-.182-.465l-4.8-5.02a.573.573 0 00-.883.056.687.687 0 00.047.874L12.162 10l-4.355 4.555z" fill="#989898"/></svg>
      </div>
      <table class="schedule-table">
        <thead class="schedule-table__head">
          <tr>
            <th>
              <DatePicker
                :popover="{ placement: 'bottom-end', visibility: 'click' }"
                v-model="valueDatePicker"
                color="red"
                is-dark
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'schedule__icon'" @click="togglePopover()" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.65 8.566h16.71M14.164 12.227h.009M10.004 12.227h.009M5.835 12.227h.009M14.164 15.871h.009M10.004 15.871h.009M5.835 15.871h.009M13.79 1.625V4.71M6.217 1.625V4.71" stroke="#989898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M13.973 3.105H6.035c-2.753 0-4.473 1.534-4.473 4.353v8.484c0 2.864 1.72 4.433 4.473 4.433h7.93c2.762 0 4.473-1.543 4.473-4.362V7.458c.008-2.819-1.703-4.353-4.465-4.353z" stroke="#989898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </template>
              </DatePicker>
            </th>
            <th>
              <div>{{ datePrev3.format('dd') }}</div>
              <div class="schedule-table__day">{{ datePrev3.format('DD.MM.YYYY') }}</div>
            </th>
            <th>
              <div>{{ datePrev2.format('dd') }}</div>
              <div class="schedule-table__day">{{ datePrev2.format('DD.MM.YYYY') }}</div>
            </th>
            <th>
              <div>{{ datePrev1.format('dd') }}</div>
              <div class="schedule-table__day">{{ datePrev1.format('DD.MM.YYYY') }}</div>
            </th>
            <th class="schedule-table__current">
              <div>{{ selectDate.format('dd') }}</div>
              <div class="schedule-table__day">{{ selectDate.format('DD.MM.YYYY') }}</div>
            </th>
            <th>
              <div>{{ dateNext1.format('dd') }}</div>
              <div class="schedule-table__day">{{ dateNext1.format('DD.MM.YYYY') }}</div>
            </th>
            <th>
              <div>{{ dateNext2.format('dd') }}</div>
              <div class="schedule-table__day">{{ dateNext2.format('DD.MM.YYYY') }}</div>
            </th>
            <th>
              <div>{{ dateNext3.format('dd') }}</div>
              <div class="schedule-table__day">{{ dateNext3.format('DD.MM.YYYY') }}</div>
            </th>
          </tr>
        </thead>
        <tbody class="schedule-table__body">
          <td>6:00</td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(datePrev3,  1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(datePrev2,  1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(datePrev1,  1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(selectDate, 1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(dateNext1,  1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(dateNext2,  1,7)"/></td>
          <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfoTimePeriod(dateNext3,  1,7)"/></td>
          <template v-for="time in [ /*1,2,3,4,5,6,7,*/8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24 ]">
            <tr :key="time">
              <td>{{time - 1}}:00</td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(datePrev3,  time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(datePrev2,  time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(datePrev1,  time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(selectDate, time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(dateNext1,  time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(dateNext2,  time)" :age-list="ageList"/></td>
              <td><ClubScheduleSlot :slot-info="getTimeTableSlotInfo(dateNext3,  time)" :age-list="ageList"/></td>
            </tr>
          </template>
        </tbody>
      </table>
      <b-modal ref="modal" size="lg" centered hide-footer hide-header>
        <div class="modal-schedule">
          <div class="modal-schedule__control">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.5 15.5" v-bind:svg-inline="''" v-bind:class="'modal-event__icon'" @click="closeModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#555"><path d="M.76 15.49a.75.75 0 01-.53-1.28l14-14a.75.75 0 011.06 1.06l-14 14a.75.75 0 01-.53.22z"/><path d="M14.75 15.5a.75.75 0 01-.53-.22l-14-14A.75.75 0 011.28.22l14 14a.75.75 0 01-.53 1.28z"/></g></svg>
          </div>
          <div class="modal-schedule__body">
            <div class="modal-schedule__title">Добавить тренировку</div>
            <ClubScheduleAddForm
              ref="ClubScheduleAddForm"
              :clubId="clubId"
            />
            <div class="btn-gp">
              <b-button size="lg" variant="danger" @click="addSchedule">Сохранить</b-button>
              <div class="btn btn-link btn-lg" @click="closeModal">Отмена</div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import ClubScheduleAddForm from '@component/Form/ClubScheduleAddForm';
// import FveSelectAjaxV2Place from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Place";
// import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";
import FveSelectObject from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import ClubScheduleSlot from "@page/Club/ClubScheduleSlot";

export default {
  components: {
    ClubTemplate,
    ClubScheduleAddForm,
    // FveSelectAjaxV2Place,
    // FveSelectAjaxV2Trainer,
    FveSelectObject,
    DatePicker,
    ClubScheduleSlot
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      filter: {
        trainer: null,
        place  : null,
      },
      selectDate: new DateTime(),
      timetable: {},

      trainer: null,
      trainerList: [],
      place: null,
      placeList: [],

      loadDateStart: null,
      loadDateEnd  : null,
      ageList: {},
    };
  },
  computed: {
    valueDatePicker: {
      get() {
        return this.selectDate.toDate();
      },
      set(value) {
        this.selectDate = new DateTime(value);
        // TODO:
        this.load();
      }
    },

    datePrev3() {
      return this.selectDate.subtract(3, 'day');
    },
    datePrev2() {
      return this.selectDate.subtract(2, 'day');
    },
    datePrev1() {
      return this.selectDate.subtract(1, 'day');
    },
    dateNext1() {
      return this.selectDate.add(1, 'day');
    },
    dateNext2() {
      return this.selectDate.add(2, 'day');
    },
    dateNext3() {
      return this.selectDate.add(3, 'day');
    },
  },
  methods: {
    updateFilter() {
      this.filter.trainer = this.trainer;
      this.filter.place   = this.place;

      this.load();
    },



    getTimeTableSlotInfoTimePeriod(date, timeStart, timeEnd) {
      let dateFormat = date.format('YYYY-MM-DD');

      let info = [];
      for(let i = timeStart; i < timeEnd; i++) {
        let timeFormat = (i-1).toString();
        if(
          this.timetable[dateFormat]
          && this.timetable[dateFormat][timeFormat]
          && this.timetable[dateFormat][timeFormat].length
        ) {
          info.concat(this.timetable[dateFormat][timeFormat]);
        }
      }
      return info;
    },

    // timetable
    getTimeTableSlotInfo(date, time) {
      let dateFormat = date.format('YYYY-MM-DD');
      let timeFormat = (time-1).toString();
      if(
        this.timetable[dateFormat]
        && this.timetable[dateFormat][timeFormat]
        && this.timetable[dateFormat][timeFormat].length
      ) {
        return this.timetable[dateFormat][timeFormat];
      } else {
        return [];
      }
    },

    nextCalendar() {
      this.selectDate = this.selectDate.add(1, 'day');

      if(this.loadDateEnd.format('YYYYMMDD') < this.selectDate.add(4, 'day').format('YYYYMMDD') ){
        this.load();
      }
    },
    prevCalendar() {
      this.selectDate = this.selectDate.subtract(1, 'day');
      if(this.loadDateStart.format('YYYYMMDD') > this.selectDate.subtract(4, 'day').format('YYYYMMDD') ){
        this.load();
      }
    },


    showModal() {
      this.$refs['modal'].show();
    },
    closeModal() {
      this.$refs['modal'].hide();
    },

    addSchedule(){
      let formData = this.$refs.ClubScheduleAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = {
        date        : formData.date,
        start_time  : formData.start_time,
        tariff      : formData.tariff.id,
        court       : formData.place.id,
        trainer     : formData.trainer.id,
      };

      if(formData.repeat.id !== 'ONCE') {
        sendData.repeat = formData.repeat.id;
        sendData.span   = formData.span.id;
      }

      RequestManager.Club.addClubTimetableItem({clubId: this.clubId, info: sendData}).then( (res) => {
        this.$toasts.push({
          message: 'Запись успешно добавлена',
          duration: 4000,
        });
        this.closeModal();
        this.load();
      });
    },

    load() {
      // this.timetable = {};
      this.$set(this, 'timetable', {});

      this.loadDateStart  = this.selectDate.subtract(14, 'day');
      this.loadDateEnd    = this.selectDate.add(14, 'day');
      RequestManager.Club.getClubTimetableList({
        clubId    : this.clubId,
        startDate : this.loadDateStart,
        endDate   : this.loadDateEnd,
        trainerId : this.filter.trainer ? this.filter.trainer.id : null,
        placeId   : this.filter.place   ? this.filter.place.id   : null,
      }).then((res) => {
        for(let i = 0; i < res.results.length; i++){
          let item = res.results[i];

          if( !Object.prototype.hasOwnProperty.call(this.timetable, item.date) ) {
            // this.timetable[item.date] = {};
            this.$set(this.timetable, item.date, {});
          }

          let time = item.start_time.substr(0, 2);
          if(time[0] === '0') {
            time = time[1];
          }

          if( !Object.prototype.hasOwnProperty.call(this.timetable[item.date], time) ) {
            // this.timetable[item.date][time] = [];
            this.$set(this.timetable[item.date], time, []);
          }

          this.timetable[item.date][time].push(Object.assign({
            // TODO: fix timetable status
            status2: 'green', // orange, ?
            _date: DateTime(this.date),
          }, item));
        }
      });
    }
  },
  created() {
    this.load();

    RequestManager.Club.getClubTrainerList({
      clubId: this.clubId,
    }).then( (itemList) => {
      this.trainerList = itemList.results.map((item) => {
        return {
          id        : item.id,
          img       : item.avatar,
          name      : `${item.last_name} ${item.first_name} ${item.middle_name}`,
          tariff_set: item.tariff_set,
        };
      });
    });

    RequestManager.Club.getClubRoomList({
      clubId: this.clubId,
    }).then( (response) => {
      this.placeList = response.results;
    });

    RequestManager.Reference.getClubAgeList({}).then((list) => {
      const _ageList = {};
      list.forEach(i => _ageList[i.key] = i.value);
      this.ageList = _ageList;
    });
  }
};
</script>

<style lang="scss" scoped>
// schedule
.schedule {
  position: relative;
  margin-bottom: 10px;
  .schedule__icon {
    cursor: pointer;
  }
  .schedule__control {
    .schedule__control-icon {
      position: absolute;
      top: 14px;
      cursor: pointer;
      path {
        transition: all .2s ease-in-out;
      }
      &:hover {
        path {
          fill: var(--color-prime);
        }
      }
      &--prev {
        left: 50px;
      }
      &--next {
        right: 0;
      }
    }
  }
  .schedule-table {
    width: 100%;
    th:first-child, td:first-child {
      width: 50px;
      max-width: 50px;
    }
    th {
      height: 52px;
      color: var(--text-sub);
      font-size: 14px;
      text-align: center;
      vertical-align: middle;
    }
    td:first-child {
      height: 40px;
      color: var(--text-sub);
      font-size: 12px;
      text-align: center;
      vertical-align: middle;
    }
    td:not(:first-child) {
      border: 1px solid #2A2A2A;
    }
    .schedule-table__head {
      th.schedule-table__current {
        background-color: var(--br-block-sub);
        .schedule-table__day {
          color: var(--color-prime);
        }
      }
    }
    .schedule-table__body {
      td {
        position: relative;
      }
    }
  }
  ::v-deep .vc-popover-content-wrapper {
    .vc-popover-content {
      margin-top: 15px;
      transform: translateX(14px);
    }
  }
}

// slot
.slot {
  position: absolute;
  width: 100%;
  .slot__num {
    position: absolute;
    left: -8px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    border-radius: 50%;
    background-color: var(--color-prime);
    z-index: 2;
  }
  .slot__ell {
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    cursor: pointer;
    .slot__text {
      color: #FFFFFF;
      font-size: 12px;
    }
    &:nth-child(2) {
      margin-top: 8px;
      margin-left: 8px;
    }
    &--green {
      border: 1px solid #CAF12E;
      background-color: rgba(202, 241, 46, 0.12);
    }
    &--orange {
      border: 1px solid #FFC107;
      background-color: rgba(255, 193, 7, 0.15);
    }
    &--red {
      border: 1px solid #E54C4C;
      background-color: rgba(220, 53, 69, 0.15);
    }
  }
}
.slot-dropdown {
  position: absolute;
  right: 0;
  top: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #CAF12E;
  background-color: var(--bg-prime);
  z-index: 5;
  .slot-dropdown__close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 10px;
    height: auto;
    cursor: pointer;
    path {
      transition: all .2s ease-in-out;
    }
    &:hover {
      path {
        fill: var(--color-prime);
      }
    }
  }
  .slot-dropdown__head {
    margin-bottom: 8px;
    padding-right: 10px;
    color: var(--text-descr);
    font-size: 14px;
    white-space: nowrap;
  }
  .slot-dropdown__list {
    .slot-dropdown__item {
      margin-bottom: 8px;
      color: var(--text-sub);
      font-size: 14px;
      white-space: nowrap;
      cursor: pointer;
      transition: all .2s ease-in-out;
      &:hover {
        color: var(--color-prime);
      }
    }
  }
}

// modal-event
.modal-schedule {
  position: relative;
  .modal-schedule__body {
    padding: 8px;
    .modal-schedule__title {
      margin-bottom: 24px;
      color: var(--text-prime);
      font-weight: 500;
      font-size: 17px;
    }
  }
  .modal-schedule__control {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .modal-event__icon {
      cursor: pointer;
      path {
        transition: all .2s ease-in-out;
      }
      &:hover {
        path {
          fill: var(--color-prime);
        }
      }
    }
  }
}
</style>
